var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","icon":"mdi-clipboard-text","inline":"","title":"MaGic - Marktgebietskonfigurator"}},[_c('v-text-field',{attrs:{"label":"Search by Name or Kunde"},on:{"keyup":_vm.loadData},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.marketAreas,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.paginationMeta.totalItems,"calculate-widths":"","item-class":_vm.marketAreaItemClass},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.areaState",fn:function(ref){
var item = ref.item;
return [(!_vm.isAdmin && item.areaState === 'approved')?[_c('v-select',{key:("state-" + (item.id) + "-" + _vm.stateKey),staticStyle:{"max-width":"275px"},attrs:{"value":item.areaState,"items":[
                  { text: _vm.$t('areaState.approved'), value: 'approved' } ],"dense":"","solo-inverted":"","flat":"","outlined":"","disabled":true,"menu-props":{ offsetY: true, bottom: true },"hide-details":""},on:{"change":function($event){return _vm.changeAreaState($event, item)}}})]:_c('v-select',{key:("state-" + (item.id) + "-" + _vm.stateKey),staticStyle:{"max-width":"275px"},attrs:{"value":item.areaState,"items":_vm.areaStateValues,"dense":"","solo-inverted":"","flat":"","outlined":"","disabled":_vm.isAreaReadOnly(item),"menu-props":{ offsetY: true, bottom: true },"hide-details":""},on:{"change":function($event){return _vm.changeAreaState($event, item)}}})]}},{key:"item.updatedAt",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updatedAt))+" ")]}},{key:"item.areas",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.amount(item))+" ")]}},{key:"item.price",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calculatePrice(item))+" ")]}},{key:"item.customerName",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.customerName || item.customerId)+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":("/area/" + (item.id))}},[_c('v-icon',[_vm._v(_vm._s(_vm.isAreaReadOnly(item) ? "mdi-eye" : "mdi-pencil"))])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showCopyMarketAreaConfirm(item)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{attrs:{"icon":"","to":("/print-area/" + (item.id)),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.excelExport(item.id)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])})],1)],1)],1),_c('v-row',{staticClass:"pt-6",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" Aktuelle Marktgebiete ")]),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","to":"/area"}},[_vm._v(" Zum aktuellen Marktgebiet ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.resetAndGo}},[_vm._v(" Neues Marktgebiet anlegen ")])],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showCopyDialog),callback:function ($$v) {_vm.showCopyDialog=$$v},expression:"showCopyDialog"}},[_c('v-card',[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [_c('v-card-title',{staticClass:"headline primary lighten-2 white--text",domProps:{"innerHTML":_vm._s('Marktgebiet kopieren')}}),_c('v-card-text',{staticClass:"pt-6"},[_c('v-row',[(_vm.marketAreaToCopy)?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{key:_vm.marketAreaToCopyKey,attrs:{"label":"Name *","outlined":"","error-messages":errors},model:{value:(_vm.marketAreaToCopy.title),callback:function ($$v) {_vm.$set(_vm.marketAreaToCopy, "title", $$v)},expression:"marketAreaToCopy.title"}})]}}],null,true)})],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showCopyDialog = false}}},[_vm._v("Abbrechen")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.copyingMarketArea,"disabled":invalid},on:{"click":_vm.copyMarketArea}},[_vm._v(" Kopieren ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }




































































































































































import dayjs from "dayjs";
import { isEqual } from "lodash";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { DataOptions } from "vuetify";
import { State } from "vuex-class";
import { marketAreaResource } from "../../api/resources";
import {
  AreaState,
  AreaType,
  ListMarketAreaGroupDto,
  MarketAreaListDto,
  PaginatedMarketAreaListDto,
  PaginationMetadata,
} from "../../api/types";
import { RoleMixin } from "../../mixins/role";
import { currencyFormatter } from "../../plugins/formatter";
import { marketArea, marketAreaOverview, Prices } from "../../store";
import {
  MarketAreaActions,
  SaveAreaPayload,
} from "../../store/marketarea/types";
import { MarketAreaOverviewActions } from "../../store/marketareaoverview";

interface DataOptionsMain extends DataOptions {
  searchQuery: string
}

@Component({})
export default class MarketAreaDashboard extends mixins(RoleMixin) {
  loading = false;
  options: DataOptionsMain = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ["id"],
    sortDesc: [true],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    searchQuery: '',
  };
  @State("prices") prices!: Prices;

  searchQuery: string='';

  marketAreas: MarketAreaListDto[] = [];
  paginationMeta: PaginationMetadata = {
    itemCount: 0,
    totalItems: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
  };
  marketAreaToCopyKey: string = "marketAreaToCopy";

  stateKey: number = 0;

  marketAreaToCopy?: MarketAreaListDto;
  copyingMarketArea: boolean = false;
  showCopyDialog: boolean = false;

  @marketArea.Action(MarketAreaActions.clearAll)
  clearSelectedAreas!: () => boolean;

  @marketArea.Action(MarketAreaActions.updateActiveArea)
  updateActiveArea!: (val: AreaType) => void;

  @marketAreaOverview.Action(MarketAreaOverviewActions.load)
  load!: (pagination: {
    page: number;
    items: number;
    sortBy?: keyof MarketAreaListDto;
    sort?: "ASC" | "DESC";
    searchQuery?: string
  }) => Promise<PaginatedMarketAreaListDto>;

  @marketAreaOverview.Action(MarketAreaOverviewActions.excelExport)
  excelExport!: (id: number) => Promise<void>;

  @marketArea.Action(MarketAreaActions.getInitialMarkedArea)
  getInitialMarkedArea!: (payload: { id: string; detail: boolean }) => boolean;

  @marketArea.Action(MarketAreaActions.saveMarketArea)
  saveMarketArea!: (payload: SaveAreaPayload) => Promise<void>;

  async created() {
    await this.loadData();
  }

  @Watch("options", { deep: true })
  optionsWatch(current: DataOptionsMain, previous: DataOptionsMain) {
    if (
      current.page !== previous.page ||
      current.itemsPerPage !== previous.itemsPerPage ||
      !isEqual(current.sortBy, previous.sortBy) ||
      !isEqual(current.sortDesc, previous.sortDesc)
    ) {
      this.loadData();
    }
  }

  async loadData() {
    this.options.searchQuery = this.searchQuery
    this.loading = true;
    try {
      const data = await this.load({
        page: this.options.page,
        items: this.options.itemsPerPage,
        sort: this.options.sortDesc[0] ? "DESC" : "ASC",
        sortBy: this.options.sortBy[0] as keyof MarketAreaListDto,
        searchQuery: this.options.searchQuery ? this.options.searchQuery : "",
      });

      this.marketAreas = data.items;
      this.paginationMeta = data.meta;
    } finally {
      this.loading = false;
    }
  }

  async resetAndGo() {
    this.clearSelectedAreas();

    const res = await this.$confirm(
      "Auswahl nach Amtlichem Gemeindeschlüssel (KGS) oder Postleitzahlen (PLZ)",
      {
        title: "KGS oder PLZ?",
        color: "success",
        buttonTrueText: "KGS",
        buttonFalseText: "PLZ",
      },
    );

    if (!res) {
      this.updateActiveArea(AreaType.plz);
    } else {
      this.updateActiveArea(AreaType.kgs8);
    }

    await this.$router.push("/area");
  }

  get headers() {
    return [
      { text: "ID", value: "id" },
      { text: "Status", value: "areaState", maxWidth: "275px" },
      { text: "Name", value: "title" },
      { text: "Zul. geändert", value: "updatedAt" },
      {
        text: "Anz. Teilmärkte",
        value: "areas",
        align: "end",
        sortable: false,
      },
      { text: "Kosten", value: "price", align: "end", sortable: false },
      { text: "Kunde", value: "customerName", align: "end" },
      { text: "", value: "actions", align: "end", sortable: false },
    ];
  }

  marketAreaItemClass(item: MarketAreaListDto): string {
    return item.areaState === AreaState.inProgress
      ? "red lighten-5"
      : item.areaState === AreaState.inPreparation
      ? "orange lighten-5"
      : "green lighten-5";
  }

  calculatePrice(marketArea: MarketAreaListDto) {
    return currencyFormatter.format(
      this.prices.basePrice +
        this.amount(marketArea) * this.prices.pricePerUnit,
    );
  }

  defaultGroup(item: MarketAreaListDto): ListMarketAreaGroupDto {
    return item.marketAreaGroups.find((group) => group.isDefault)!;
  }

  amount(item: MarketAreaListDto) {
    return (
      item.marketAreaGroups.filter(
        (group) => !group.isDefault && group.selectedAreas?.length,
      ).length + this.defaultGroup(item)?.selectedAreas.length
    );
  }

  formatDate(date?: string) {
    if (date) {
      return dayjs(date).format("DD.MM.YYYY, HH:mm:ss");
    }
    return "";
  }

  async changeAreaState(value: AreaState, item: MarketAreaListDto) {
    if (
      (this.isAdmin && value === AreaState.approved) ||
      (!this.isAdmin && value === AreaState.inPreparation)
    ) {
      const res = await this.$confirm(
        "Nach Freigabe kann das Marktgebiet nicht mehr verändert werden. Fortfahren?",
        {
          title: "Freigabe",
          color: "success",
          buttonTrueText: "Ja",
          buttonFalseText: "Nein",
        },
      );
      if (!res) {
        // force reload of select
        ++this.stateKey;
        return;
      }
    }
    (document.activeElement as HTMLInputElement).blur();
    item.areaState = value;
    marketAreaResource.patch(`${item.id}/state`, {
      areaState: item.areaState,
    });
  }

  showCopyMarketAreaConfirm(item: MarketAreaListDto) {
    this.marketAreaToCopy = { ...item };
    this.showCopyDialog = true;
    this.marketAreaToCopyKey = "marketAreaToCopy-" + this.marketAreaToCopy.id;
  }

  async copyMarketArea() {
    if (!this.marketAreaToCopy) {
      return;
    }
    try {
      this.copyingMarketArea = true;
      await this.getInitialMarkedArea({
        id: this.marketAreaToCopy!.id.toString(),
        detail: false,
      });
      await this.saveMarketArea({
        marketArea: {
          title: this.marketAreaToCopy.title,
          customerId: this.marketAreaToCopy.customerId,
          customerName: this.marketAreaToCopy.customerName,
          marketAreaGroups: [],
          activeArea: AreaType.kgs2,
        },
      });
    } finally {
      this.copyingMarketArea = false;
      this.showCopyDialog = false;
    }
    this.loadData();
  }

  get areaStateValues() {
    if (!this.isAdmin) {
      return [
        {
          text: this.$t("areaState.inProgress").toString(),
          value: AreaState.inProgress,
        },
        {
          text: this.$t("areaState.inPreparation").toString(),
          value: AreaState.inPreparation,
        },
      ];
    }
    return [
      {
        text: this.$t("areaState.inProgress").toString(),
        value: AreaState.inProgress,
      },
      {
        text: this.$t("areaState.inPreparation").toString(),
        value: AreaState.inPreparation,
      },
      {
        text: this.$t("areaState.approved").toString(),
        value: AreaState.approved,
      },
    ];
  }
}

import Vue from "vue";
import Component from "vue-class-component";
import { AreaState, MarketAreaGetDto } from "../api/types";

@Component
export class RoleMixin extends Vue {
  get isAdmin(): boolean {
    return this.$auth.token?.roles.includes("ROLE_MAGIC_ADMIN");
  }

  isAreaReadOnly(area: MarketAreaGetDto) {
    if (!area) {
      return false;
    }
    return this.isAdmin
      ? area.areaState === AreaState.approved
      : area.areaState !== AreaState.inProgress;
  }
}












import Vue from "vue";
import Component from "vue-class-component";
import MarketAreaDashboard from "./MarketAreaDashboard.vue";

@Component({
  components: { MarketAreaDashboard },
})
export default class Layout extends Vue {}
